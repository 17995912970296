import React from "react";
import BillingAddress from "Components/Cards/BillingAddress";
import DeliveryAddress from "Components/Cards/DeliveryAddress";
import { ReactComponent as BillingIcon } from "Assets/Icons/Billing.svg";
import { ReactComponent as DeliveryIcon } from "Assets/Icons/Delivery.svg";

const Addresses = ({ data }) => (
    <React.Fragment>
        {data.paymentAddress && <div className="order-card">
            <div className="header">
                <div className="icon">
                    <BillingIcon />
                </div>
                Billing Address
            </div>
            <BillingAddress data={data.paymentAddress}/>
        </div>}
        {data.deliveryAddress && <div className="order-card">
            <div className="header">
                <div className="icon">
                    <DeliveryIcon />
                </div>
                Delivery Address
            </div>
                <DeliveryAddress
                    deliveryAddress={data.deliveryAddress}
                    deliveryDetails={data.deliveryAddressDetails}
                    customerDetails={data.customerContactDetails}
            />
        </div>}
    </React.Fragment>
);

export default Addresses;