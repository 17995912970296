import React from "react";

const BillingAddress = ({ data }) => (
    <div className="container">
        {data.firstName &&
            <div className="content">
                <span>Name</span>
                <div>{data.firstName} {data.lastName}</div>
            </div>
        }
        {data.formattedAddress &&
            <div className="content">
                <span>Address</span>
                <div>{data.formattedAddress}</div>
            </div>
        }
        {data.email &&
            <div className="content">
                <span>Email</span>
                <div>{data.email}</div>
            </div>
        }
        {data.line1 &&
            <div className="content">
                <span>Line 1</span>
                <div>{data.line1}</div>
            </div>
        }
        {data.line2 &&
            <div className="content">
                <span>Line 2</span>
                <div>{data.line2}</div>
            </div>
        }
        {data.town &&
            <div className="content">
                <span>Town</span>
                <div>{data.town}</div>
            </div>
        }
        {data.postalCode &&
            <div className="content">
                <span>Post Code</span>
                <div>{data.postalCode}</div>
            </div>
        }
        {data.country && data.country.isocode &&
            <div className="content">
                <span>Country</span>
                <div>{data.country.isocode}</div>
            </div>
        }
    </div>
);

export default BillingAddress;