import React from "react";

const Customer = ({ data, store, sales }) => (
    <div className="container">
        {data.name &&
            <div className="content">
                <span>Name</span>
                <div>{data.name}</div>
            </div>
        }
        {data.uid &&
            <div className="content">
                <span>Email</span>
                <div>{data.uid}</div>
            </div>
        }
        {store &&
            <div className="content">
                <span>Store</span>
                <div>{store}</div>
            </div>
        }
        {sales &&
            <div className="content">
                <span>Sales App</span>
                <div>{sales}</div>
            </div>
        }
    </div>
);

export default Customer;