import React, { useState } from "react";
import moment from "moment";
import { ReactComponent as Chevron } from "Assets/Icons/Chevron_Right.svg";

const Ticket = ({ data }) => {
    const [hidden, setHidden] = useState(true);
    const [entryHidden, setEntryHidden] = useState(true);

    const onHide = () => {
        setHidden(hidden ? false : true);
    };

    const onEntryHide = () => {
        setEntryHidden(entryHidden ? false : true);
    };

    return (
        <div className="container">
            {data.ticketID &&
                <div className="content">
                    <span>ID</span>
                    <div>{data.ticketID}</div>
                </div>
            }
            {data.state &&
                <div className="content">
                    <span>State</span>
                    <div>{data.state}</div>
                </div>
            }
            {data.priority &&
                <div className="content">
                    <span>Priority</span>
                    <div>{data.priority}</div>
                </div>
            }
            {data.category &&
                <div className="content">
                    <span>Category</span>
                    <div>{data.category}</div>
                </div>
            }
            {data.headline &&
                <div className="content">
                    <span>Headline</span>
                    <div>{data.headline}</div>
                </div>
            }
            {data.ticketEvents && <div className="details-header" onClick={onHide}>
                Events
                <div className={`${hidden ? "active" : "inactive"}`}>
                    <Chevron />
                </div>
            </div>}
                {data.ticketEvents && data.ticketEvents.map((event, i) => (
                    <div className={`details-container ${hidden ? "hidden" : ""} ${i % 2 ? "" : "staggered-details-container"}`} key={`tick-event-${i}`}>
                        {event.interventionType &&
                            <div className="content">
                                <span>Type</span>
                                <div>{event.interventionType}</div>
                            </div>
                        }
                        {event.reason &&
                            <div className="content">
                                <span>Reason</span>
                                <div>{event.reason}</div>
                            </div>
                        }
                        {event.timeCreated &&
                            <div className="content">
                                <span>Created At</span>
                                <div>{moment(new Date(event.timeCreated)).format("YYYY-MM-DD HH:mm:ss")}</div>
                            </div>
                        }
                        {event.text &&
                            <div className="content">
                                <span>Notes</span>
                                <div>{event.text}</div>
                            </div>
                        }
                        {event.name && event.name.name &&
                            <div className="content">
                                <span>User</span>
                                <div>{event.name.name}</div>
                            </div>
                        }
                        {event.entries && event.entries.length > 0 && <div className="details-header" onClick={onEntryHide}>
                            Changes
                            <div className={`${entryHidden ? "active" : "inactive"}`}>
                                <Chevron />
                            </div>
                        </div>}
                        {event.entries && event.entries.length > 0 && event.entries.map((entry, i) => (
                            <div className={`details-container ${entryHidden ? "hidden" : ""} ${i % 2 ? "" : "staggered-details-container"}`} key={`tick-event-entry-${i}`}>
                                {entry.changedAttribute &&
                                    <div className="content">
                                        <span>Value Changed</span>
                                        <div>{entry.changedAttribute}</div>
                                    </div>
                                }
                                {entry.oldValue &&
                                    <div className="content">
                                        <span>Old Value</span>
                                        <div>{entry.oldValue}</div>
                                    </div>
                                }
                                {entry.newValue &&
                                    <div className="content">
                                        <span>New Value</span>
                                        <div>{entry.newValue}</div>
                                    </div>
                                }
                            </div> 
                        ))}
                    </div> 
                ))}
        </div>
    );
};

export default Ticket;