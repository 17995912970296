import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { ReactComponent as Icon } from "Assets/Icons/OrderNumber.svg";
import "./styles.scss";

const Order = ({ search, code, name, email, mobile, created }) => {
    var localCreatedDateTime = moment(new Date(created)).format("YYYY-MM-DD HH:mm:ss");
    return (
        <div className="results-card">
            <div className="card-header">
                <div className="card-header-icon">
                    <Icon />
                </div>
                #{code}
                <div className="order-container">
                    <div onClick={() => search(null, null, null, code)}>
                        🠢
                    </div>
                </div>
            </div>
            <div className="content-container">
                <div className="content">
                    <span>Created</span>
                    <div>{localCreatedDateTime}</div>
                </div>
                {name && <div className="content">
                    <span>Customer</span>
                    <div>{name}</div>
                </div>}
                {email && <div className="content">
                    <span>Email</span>
                    <div>{email}</div>
                </div>}
                {mobile && <div className="content">
                    <span>Mobile No.</span>
                    <div>{mobile}</div>
                </div>}
            </div>
        </div>
    );
};

Order.propTypes = {
    code: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    name: PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
    search: PropTypes.func.isRequired,
};

export default Order;