import React from "react";

const DeliveryAddress = ({ deliveryAddress, customerDetails, deliveryDetails }) => (
    <div className="container">
        {deliveryAddress.firstName &&
            <div className="content">
                <span>Name</span>
                <div>{deliveryAddress.firstName} {deliveryAddress.lastName}</div>
            </div>
        }
        {deliveryDetails.storeId &&
            <div className="content">
                <span>Store Id</span>
                <div>{deliveryDetails.storeId}</div>
            </div>
        }
        {deliveryDetails.storeName &&
            <div className="content">
                <span>Store Name</span>
                <div>{deliveryDetails.storeName}</div>
            </div>
        }
        {deliveryAddress.formattedAddress &&
            <div className="content">
                <span>Address</span>
                <div>{deliveryAddress.formattedAddress}</div>
            </div>
        }
        {deliveryAddress.email &&
            <div className="content">
                <span>Email</span>
                <div>{deliveryAddress.email}</div>
            </div>
        }
        {customerDetails.mobile &&
            <div className="content">
                <span>Mobile</span>
                <div>{customerDetails.mobile}</div>
            </div>
        }
        {customerDetails.landline &&
            <div className="content">
                <span>Landline</span>
                <div>{customerDetails.landline}</div>
            </div>
        }
        {deliveryAddress.line1 &&
            <div className="content">
                <span>Line 1</span>
                <div>{deliveryAddress.line1}</div>
            </div>
        }
        {deliveryAddress.line2 &&
            <div className="content">
                <span>Line 2</span>
                <div>{deliveryAddress.line2}</div>
            </div>
        }
        {deliveryAddress.town &&
            <div className="content">
                <span>Town</span>
                <div>{deliveryAddress.town}</div>
            </div>
        }
        {deliveryAddress.postalCode &&
            <div className="content">
                <span>Post Code</span>
                <div>{deliveryAddress.postalCode}</div>
            </div>
        }
        {deliveryAddress.country && deliveryAddress.country.isocode &&
            <div className="content">
                <span>Country</span>
                <div>{deliveryAddress.country.isocode}</div>
            </div>
        }
    </div>
);

export default DeliveryAddress;