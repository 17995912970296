import React from "react";
import PropTypes from "prop-types";
import Totals from "Components/Cards/Totals";
import Transaction from "Components/Cards/Transaction";
import { ReactComponent as TotalsIcon } from "Assets/Icons/Totals.svg";
import { ReactComponent as TransactionIcon } from "Assets/Icons/Transactions.svg";

const Transactions = ({ data }) => (
    <React.Fragment>
        <div className="order-card">
            <div className="header">
                <div className="icon">
                    <TotalsIcon />
                </div>
                Totals
            </div>
            <Totals data={data} />
        </div>
        {data.paymentTransactionList && <div className="order-card">
            <div className="header">
                <div className="icon">
                    <TransactionIcon />
                </div>
                Transactions
            </div>
            {data.paymentTransactionList.map((transaction, i) => <Transaction key={`transaction-${i}`} data={transaction}/>)}
        </div>}
    </React.Fragment>
);

Transactions.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Transactions;