import React, { useState } from "react";
import moment from "moment";
import { ReactComponent as Chevron } from "Assets/Icons/Chevron_Right.svg";

const Consignment = ({ data, code, items }) => {
    const [contentsHidden, setContentsHidden] = useState(true);
    const [entriesHidden, setEntriesHidden] = useState(true);
    const [packagesHidden, setPackagesHidden] = useState(true);

    const onContentsHide = () => {
        setContentsHidden(contentsHidden ? false : true);
    };
    const onEntriesHide = () => {
        setEntriesHidden(entriesHidden ? false : true);
    };

    const onPackagesHide = () => {
        setPackagesHidden(packagesHidden ? false : true);
    };

    return (
        <div className="container">
            {data.code &&
                <div className="content">
                    <span>Code</span>
                    <div>{data.code}</div>
                </div>
            }
            {data.carrier &&
                <div className="content">
                    <span>Carrier</span>
                    <div>{data.carrier}</div>
                </div>
            }
            {data.shippingDate &&
                <div className="content">
                    <span>Shipping Date</span>
                    <div>{moment(new Date(data.shippingDate)).format("YYYY-MM-DD HH:mm:ss")}</div>
                </div>
            }
            {data.status &&
                <div className="content">
                    <span>Status</span>
                    <div>{data.status}</div>
                </div>
            }
            {data.warehouseCode &&
                <div className="content">
                    <span>Warehouse</span>
                    <div>{data.warehouseCode}</div>
                </div>
            }
            {data.trackingID &&
                <div className="content">
                    <span>Tracking No.</span>
                    <div>{data.trackingID}</div>
                </div>
            }
            {data.entries && <div className="details-header" onClick={onEntriesHide}>
                Entries
                <div className={`${entriesHidden ? "active" : "inactive"}`}>
                    <Chevron />
                </div>
            </div>}
            {data.entries && data.entries.map((entry, i) => (
                <div className={`details-container ${entriesHidden ? "hidden" : ""} ${i % 2 ? "" : "staggered-details-container"}`} key={`consignment-entry-${i}`}>
                    {entry.shippedQuantity &&
                        <div className="content">
                            <span>Quantity Shipped</span>
                            <div>{entry.shippedQuantity}</div>
                        </div>
                    }
                    {entry.entryProductCode &&
                        <div className="content">
                            <span>SKU</span>
                            <div>{entry.entryProductCode}</div>
                        </div>
                    }
                    {entry.entryProductName &&
                        <div className="content">
                            <span>Product</span>
                            <div>{entry.entryProductName}</div>
                        </div>
                    }
                    {entry.totalPrice > 0 &&
                        <div className="content">
                            <span>Total Price</span>
                            <div>£{entry.totalPrice}</div>
                        </div>
                    }
                    {entry.status &&
                        <div className="content">
                            <span>Status</span>
                            <div>{entry.status}</div>
                        </div>
                    }
                </div> 
            ))}
            {data.packages && <div className="details-header" onClick={onPackagesHide}>
                Packages
                <div className={`${packagesHidden ? "active" : "inactive"}`}>
                    <Chevron />
                </div>
            </div>}
            {data.packages && data.packages.map((pck, i) => (
                <div className={`details-container ${packagesHidden ? "hidden" : ""} ${i % 2 ? "" : "staggered-details-container"}`} key={`consignment-package-${i}`}>
                    {pck.boxNumber &&
                        <div className="content">
                            <span>Box Number</span>
                            <div>{pck.boxNumber}</div>
                        </div>
                    }
                    {pck.packageNumber &&
                        <div className="content">
                            <span>Tracking Number</span>
                            <div>{pck.packageNumber}</div>
                        </div>
                    }
                    {pck.shipDate &&
                        <div className="content">
                            <span>Shipping Date</span>
                            <div>{moment(new Date(pck.shipDate)).format("YYYY-MM-DD HH:mm:ss")}</div>
                        </div>
                    }
                    {pck.trackingUrl &&
                        <div className="content">
                            <span>Tracking Url</span>
                            <div>{pck.trackingUrl}</div>
                        </div>
                    }
                    {pck.status &&
                        <div className="content">
                            <span>Status</span>
                            <div>{pck.status}</div>
                        </div>
                    }
                    {pck.contents && 
                        <div className="details-header" onClick={onContentsHide}> 
                            Package Contents
                            <div className={`${contentsHidden ? "active" : "inactive"}`}>
                                <Chevron />
                            </div>
                        </div>
                    }
                    {pck.contents && pck.contents.map((entry, i) => (
                        <div className={`details-container ${contentsHidden ? "hidden" : ""} ${i % 2 ? "" : "staggered-details-container"}`} key={`package-entry-${i}`}>
                            {entry.skuBoxId &&
                                <div className="content">
                                    <span>SKU</span>
                                    <div>{entry.skuBoxId}</div>
                                </div>
                            }
                            <div className="content">
                                <span>Qty Dispatched</span>
                                <div>{entry.contentQtyDistpached}</div>
                            </div>
                            <div className="content">
                                <span>Qty Ordered</span>
                                <div>{entry.contentQtyOrdered}</div>
                            </div>
                        </div> 
                    ))}
                </div> 
            ))}
        </div>
    );
};

export default Consignment;