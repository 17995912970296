import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as Chevron } from "Assets/Icons/Chevron_Right.svg";
import { ReactComponent as Entry } from "Assets/Icons/Entry.svg";
import { URLS } from "Constants/urls.js"
import getError from "Utils/errorHandling.js";

const ReturnRequest = ({ data, order, isSupervisor }) => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const payload = {
        order,
        returnCode: data.code,
    };
    const showRejectApprove = data.status !== null && data.status === "WAIT";
    const [hidden, setHidden] = useState(true);

    const onHide = () => {
        setHidden(hidden ? false : true);
    };

    const onReject = async (e) => {
        e.preventDefault();

        try {
            const body = JSON.stringify(payload);
            const response = await window.Gnatta.http.post(`${URLS}/refund/reject`, { body });
            const result = JSON.parse(response);
            if(result.error) {
                setError(result.error);
            } else {
                setError("");
                setSuccess("Success! The return request has been rejected.");
            }
        } catch(err) {
            setError(getError(err));
        }
    };

    const onApprove = async (e) => {
        e.preventDefault();

        try {
            const body = JSON.stringify(payload);
            const response = await window.Gnatta.http.post(`${URL}/refund/approve`, { body });
            const result = JSON.parse(response);
            if(result.error) {
                setError(result.error);
            } else {
                setError("");
                setSuccess("Success! The return request has been approved.");
            }
        } catch(err) {
            setError(getError(err));
        }
    };

    const getHeader = () => {
        if(data.refundDeliveryAmount != null && data.refundDeliveryAmount.value > 0) {
            return "Delivery Refund";
        } else if (data.refundProductAmount != null && data.refundProductAmount.value > 0) {
            return "Product Refund";
        } else {
            return "Refund";
        }
    }

    return (
        <div className="container">
            <div className="inner-header">
                <div className="icon">
                    <Entry />
                </div>
                {getHeader()}
            </div>
            <div className="subheader">
                {data.rma && data.rma}
                {isSupervisor && <div className="approve-reject">
                    {showRejectApprove && <React.Fragment>
                        <button onClick={onReject}>Reject</button>
                        <button onClick={onApprove}>Approve</button>
                    </React.Fragment>}
                </div>}
            </div>
            <div className="message-content">
                {error && 
                    <div className="error">{error}</div>
                }
                {success &&
                    <div className="success">{success}</div>
                }
            </div>
            {data.code &&
                <div className="content">
                    <span>Code</span>
                    <div>{data.code}</div>
                </div>
            }
            {data.status &&
                <div className="content">
                    <span>Status</span>
                    <div>{data.status}</div>
                </div>
            }
            {data.refundDeliveryAmount > 0 &&
                <div className="content">
                    <span>Amount (Delivery)</span>
                    <div>{data.formattedRefundDeliveryAmount}</div>
                </div>
            }
            {data.refundProductAmount > 0 &&
                <div className="content">
                    <span>Amount (Product)</span>
                    <div>{data.formattedRefundProductAmount}</div>
                </div>
            }
            {data.entries && <div className="details-header" onClick={onHide}>  
                Entries
                <div className={`${hidden ? "active" : "inactive"}`}>
                    <Chevron />
                </div>
            </div>}
            {data.entries && data.entries.map((entry, i) => (
                <div className={`details-container ${hidden ? "hidden" : ""} ${i % 2 ? "" : "staggered-details-container"}`} key={`return-request-entry-${i}`}>
                    {entry.reason &&
                        <div className="content">
                            <span>Reason</span>
                            <div>{entry.reason}</div>
                        </div>
                    }
                    {entry.status &&
                        <div className="content">
                            <span>Status</span>
                            <div>{entry.status}</div>
                        </div>
                    }
                    {entry.formattedAmount &&
                        <div className="content">
                            <span>Amount Refunded</span>
                            <div>{entry.formattedAmount}</div>
                        </div>
                    }
                    {entry.expectedQuantity > 0 &&
                        <div className="content">
                            <span>Quantity Refunded</span>
                            <div>{entry.expectedQuantity}</div>
                        </div>
                    }
                    {entry.action &&
                        <div className="content">
                            <span>Action</span>
                            <div>{entry.action}</div>
                        </div>
                    }
                    {entry.notes &&
                        <div className="content">
                            <span>Notes</span>
                            <div>{entry.notes}</div>
                        </div>
                    }
                    {entry.orderEntry && entry.orderEntry.product &&
                        <React.Fragment>
                            {entry.orderEntry.product.code && entry.orderEntry.product.name && entry.orderEntry.basePrice.formattedValue &&
                                <div className="content">
                                    <span>Product</span>
                                    <div>{entry.orderEntry.product.code} - {entry.orderEntry.product.name} - {entry.orderEntry.basePrice.formattedValue}</div>
                                </div>
                            }
                            {entry.orderEntry.quantity &&
                                <div className="content">
                                    <span>Quantity Ordered</span>
                                    <div>{entry.orderEntry.quantity}</div>
                                </div>
                            }
                            {entry.orderEntry.totalPrice && entry.orderEntry.totalPrice.formattedValue &&
                                <div className="content">
                                    <span>Total Price</span>
                                    <div>{entry.orderEntry.totalPrice.formattedValue}</div>
                                </div>
                            }
                        </React.Fragment>
                    }
                </div> 
            ))}
        </div>
    );
};

ReturnRequest.propTypes = {
    order: PropTypes.string.isRequired,
    isSupervisor: PropTypes.bool,
};

ReturnRequest.defaultProps = {
    isSupervisor: false,
};

export default ReturnRequest;