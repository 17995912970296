import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Pagination = ({ currentPage, totalPages, totalResults, changePage }) => {
    const maxPage = currentPage + 4;
    const minPage = currentPage - 2;
    return (
        <div className="pagination">
            {currentPage > 0 && <span className="first-last" onClick={() => changePage(0)}>First</span>}
            {currentPage > 0 && <span className="next-prev" onClick={() => changePage(currentPage - 1)}>🠠 Prev</span>}
            {Array.from(Array(totalPages), (e, x) => (x >= 1 && x > minPage) && x <= maxPage ? <span key={`page-${x}`} className={`page ${currentPage+1 === x ? "active" : null}`} onClick={() => changePage(x)}>{x}</span> : null)}
            {currentPage < totalPages-1 && <span className="next-prev" onClick={() => changePage(currentPage + 1)}>Next 🠢</span>}
            {currentPage < totalPages-1 && <span className="first-last" onClick={() => changePage(totalPages-1)}>Last</span>}
            <div className="indicator">Page {currentPage+1} - {totalResults} matching orders</div>
        </div>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    changePage: PropTypes.func.isRequired,
};

export default Pagination;