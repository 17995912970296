import Details from "Components/Result/Details/index.js";
import Addresses from "Components/Result/Addresses/index.js";
import Items from "Components/Result/Items/index.js";
import Transactions from "Components/Result/Transactions/index.js";

const SECTIONS = {
    0: Details,
    1: Addresses,
    2: Items,
    3: Transactions,
};
    
const getSection = (section) => {
    return SECTIONS[section] || null;    
};
    
export default getSection;