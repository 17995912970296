import React from "react";

const OrderItem = ({ data }) => (
    <div className="container">
        {data.product.code &&
            <div className="content">
                <span>SKU</span>
                <div>{data.product.code}</div>
            </div>
        }
        {data.product.stock && data.product.stock.stockLevel &&
            <div className="content">
                <span>Stock Level</span>
                <div>{data.product.stock.stockLevel}</div>
            </div>
        }
        {data.product.name &&
            <div className="content">
                <span>Description</span>
                <div>{data.product.name}</div>
            </div>
        }
        {data.basePrice && data.basePrice.formattedValue &&
            <div className="content">
                <span>Base Price</span>
                <div>{data.basePrice.formattedValue}</div>
            </div>
        }
        {data.totalPrice && data.totalPrice.formattedValue &&
            <div className="content">
                <span>Total Price</span>
                <div>{data.totalPrice.formattedValue}</div>
            </div>
        }
        {data.quantity &&
            <div className="content">
                <span>Quantity</span>
                <div>{data.quantity}</div>
            </div>
        }
    </div>
);

export default OrderItem;