const getError = (rawError) => {
    const response = JSON.parse(rawError);
    try {
        if (response.body) {
            const errorMessage = JSON.parse(response.body);
            return errorMessage.error;
        }
    } catch(e) {
        return response.userMessage || response.body || response.message || rawError;
    }
};

export default getError;