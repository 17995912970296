import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";  
import { ReactComponent as Icon } from "Assets/Icons/Order.svg";
import "./styles.scss";
import onOuterClick from "Utils/outerClick";

const Modal = ({ callback, show, children, header }) => {
    const ref = useRef(null);
    // when user clisk away from modal, it hides it
    onOuterClick(e => callback(e), ref);

    return (
        <div className={`modal-container ${show ? "show" : "hide"}`}>
            <div className="modal" ref={ref}>
                <div className="header">
                    <div className="icon">
                        <Icon />
                    </div>
                    {header}
                </div>
                <Scrollbars
                    className="scrollbars-modal"
                >
                    <div className="body">
                        {children}
                    </div>
                </Scrollbars>
            </div>
        </div>
    );
};

Modal.propTypes = {
    callback: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    header: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
};

export default Modal;