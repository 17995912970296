import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as DetailsIcon } from "Assets/Icons/DetailsSection.svg";
import { ReactComponent as AddressesIcon } from "Assets/Icons/AddressesSection.svg";
import { ReactComponent as ItemsIcon } from "Assets/Icons/ItemsSection.svg";
import { ReactComponent as TransactionsIcon } from "Assets/Icons/TransactionsSection.svg";
import "./styles.scss";

const ResultSectionSwapper = ({ section, change }) => (
    <div className="section-selector">
        <button className={`${section === 0 ? "active" : null}`} onClick={() => change(0)} disabled={section === 0 ? true : false}>
            <DetailsIcon/> Details
        </button>
        <button className={`${section === 1 ? "active" : null}`} onClick={() => change(1)} disabled={section === 1 ? true : false}>
            <AddressesIcon /> Addresses
        </button>
        <button className={`${section === 2 ? "active" : null}`} onClick={() => change(2)} disabled={section === 2 ? true : false}>
            <ItemsIcon /> Items
        </button>
        <button className={`${section === 3 ? "active" : null}`} onClick={() => change(3)} disabled={section === 3 ? true : false}>
            <TransactionsIcon /> Transactions
        </button>
    </div>
);

ResultSectionSwapper.propTypes = {
    change: PropTypes.func.isRequired,
    section: PropTypes.number,
};

ResultSectionSwapper.defaultProps = {
    section: 0,
};

export default ResultSectionSwapper;