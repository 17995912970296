import React from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";  
import Spinner from "Components/Spinner/index.js";
import Order from "Components/Results/Order/index.js";
import Error from "Components/Error/index.js";
import "./styles.scss";

const Results = ({searching, results, error, search}) => (
    <div className="results-container">
        {searching && <Spinner/>}
        {error && <Error message={error}/>} 
        <Scrollbars className="scrollbars">
            {results && results.orders.map((order, i) => 
                <Order
                    key={`order-${i}`}
                    code={order.code}
                    created={order.created}
                    name={order.user.name}
                    email={order.customerContactDetails.email}
                    mobile={order.customerContactDetails.mobile}
                    search={search}
                />
            )}
        </Scrollbars>
    </div>
);

Results.propTypes = {
    searching: PropTypes.bool.isRequired,
    search: PropTypes.func.isRequired,
    results: PropTypes.shape({
        orders: PropTypes.arrayOf(PropTypes.shape({
            code: PropTypes.string.isRequired,
            created: PropTypes.string.isRequired,
            user: PropTypes.shape({
                name: PropTypes.string,
            }).isRequired,
            customerContactDetails: PropTypes.shape({
                email: PropTypes.string,
                mobile: PropTypes.string,
            }).isRequired, 
        }))
    }),
    error: PropTypes.string
};

export default Results;