import React from "react";
import "./styles.scss";

const MainLogo = () => (
    <div className="logo-container">
        <div className="logo">
        </div>
    </div>
);

export default MainLogo;