import React, { useState } from "react";
import moment from "moment"
import { ReactComponent as Chevron } from "Assets/Icons/Chevron_Right.svg";

const Transaction = ({ data }) => {
    const [hidden, setHidden] = useState(true);

    const onHide = () => {
        setHidden(hidden ? false : true);
    };

    return (
        <div className="container">
            {data.paymentProvider &&
                <div className="content">
                    <span>Provider</span>
                    <div>{data.paymentProvider}</div>
                </div>
            }
            {data.requestId &&
                <div className="content">
                    <span>Request Id</span>
                    <div>{data.requestId}</div>
                </div>
            }
            {data.entries && <div className="details-header" onClick={onHide}> 
                Entries 
                <div className={`${hidden ? "active" : "inactive"}`}>
                    <Chevron />
                </div>
            </div>}
            {data.entries && data.entries.map((entry, i) => (
                <div className={`details-container ${hidden ? "hidden" : ""} ${i % 2 ? "" : "staggered-details-container"}`} key={`transaction-entry-${i}`}>
                    {entry.time &&
                        <div className="content">
                            <span>Time</span>
                            <div>{moment(new Date(entry.time)).format("YYYY-MM-DD HH:mm:ss")}</div>
                        </div>
                    }
                    {entry.paymentType &&
                        <div className="content">
                            <span>Type</span>
                            <div>{entry.paymentType}</div>
                        </div>
                    }
                    {entry.cardExpiry &&
                        <div className="content">
                            <span>Card Expiry</span>
                            <div>{entry.cardExpiry}</div>
                        </div>
                    }
                    {entry.cardNumber &&
                        <div className="content">
                            <span>Card Number</span>
                            <div>{entry.cardNumber}</div>
                        </div>
                    }
                    {entry.status &&
                        <div className="content">
                            <span>Status</span>
                            <div>{entry.status}</div>
                        </div>
                    }
                    {entry.statusDetails &&
                        <div className="content">
                            <span>Details</span>
                            <div>{entry.statusDetails}</div>
                        </div>
                    }
                    {entry.amount !== null &&
                        <div className="content">
                            <span>Amount</span>
                            <div>£{entry.amount}</div>
                        </div>
                    }
                </div> 
            ))}
        </div>
    );
};

export default Transaction;