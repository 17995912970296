import { useEffect } from "react";

export default (onOuterClick, innerRef) => {
    useEffect(() => {
        if (innerRef.current) {
          document.addEventListener("click", handleClick);
        }
  
        return () => document.removeEventListener("click", handleClick);

        function handleClick(e) {
            !innerRef.current.contains(e.target) && onOuterClick(e);
        }
    },[onOuterClick, innerRef]);
}