import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as CustomerLogo } from "Assets/Icons/CustomerName.svg";
import { ReactComponent as EmailLogo } from "Assets/Icons/Email.svg";
import { ReactComponent as OrderLogo } from "Assets/Icons/Order.svg";
import "./styles.scss";

const SearchBox = ({search}) => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [error, setError] = useState("");

	const onSearch = (e) => {
        e.preventDefault();
        
        if ((orderNumber.length === 0 && name.length === 0 && email.length === 0)) {
			setError("You must enter some values before searching");
			return;
        }

        const splitName = name.trim().split(" ");
		if(splitName[1] === undefined && name.length !== 0) {
			setError("You must enter the customer's first and last name.");
			return;
        }
        
        if(email.length > 0 && name.length === 0) {
            splitName[0] = " ";
            splitName[1] = " ";
        }
        
        setError("");
		search(splitName[0], splitName[1], email, orderNumber);
    };
    
    return (
        <div className="search-box">
            <form onSubmit={onSearch}>
                {error.length > 0 && <div className="search-error">{error}</div>}
                <div className="container">
                    <div className="input-container first">
                        <CustomerLogo/>
                        <input
                            value={name}
                            onChange={((e, v) => setName(e.target.value))}
                            placeholder="Customer's full name..."
                        />
                    </div>
                    <div className="input-container">
                        <EmailLogo/>
                        <input
                            value={email}
                            onChange={((e, v) => setEmail(e.target.value))}
                            placeholder="Email..."
                        />
                    </div>
                    <div className="input-container last">
                        <OrderLogo/>
                        <input
                            value={orderNumber}
                            onChange={((e, v) => setOrderNumber(e.target.value))}
                            placeholder="Order number..."
                        />
                    </div>
                    <button onClick={onSearch}>
                        <span>Search</span>
                    </button>
                </div>
            </form>
        </div>
    );
};

SearchBox.propTypes = {
    search: PropTypes.func.isRequired,
};

export default SearchBox;
